import styled from 'styled-components';

import { headerColors } from '../../constants/colors';
import { articles } from '../../content/articles';
import { Card } from '../card/Card';
import { CardDescription } from '../card/CardDescription';
import { CardLink } from '../card/CardLink';
import { CardList } from '../card/CardList';
import { CardTitle } from '../card/CartTitle';
import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';
import { BasicTitle } from '../title/BasicTitle';

const ArticleCard = styled(Card)`
  &:nth-child(9n + 9) {
    background-color: hsl(181, 82%, 40%);
  }

  &:nth-child(9n + 1) {
    background-color: hsl(181, 82%, 37%);
  }

  &:nth-child(9n + 5) {
    background-color: hsl(181, 82%, 34%);
  }

  &:nth-child(9n + 2) {
    background-color: hsl(181, 82%, 31%);
  }

  &:nth-child(9n + 3) {
    background-color: hsl(181, 82%, 28%);
  }

  &:nth-child(9n + 6) {
    background-color: hsl(181, 82%, 25%);
  }

  &:nth-child(9n + 7) {
    background-color: hsl(181, 82%, 22%);
  }

  &:nth-child(9n + 4) {
    background-color: hsl(181, 82%, 19%);
  }

  &:nth-child(9n + 8) {
    background-color: hsl(181, 82%, 16%);
  }
`;

export const Articles = (): JSX.Element => {
  return (
    <>
      <Header color={headerColors.article}>
        <BasicTitle>Articles</BasicTitle>
      </Header>
      <CardList>
        {articles.map((article) => {
          const { slug, subTitle, title } = article;

          return (
            <ArticleCard key={slug}>
              <CardLink to={slug}>
                <CardTitle>{title}</CardTitle>
                {subTitle === undefined ? null : <CardDescription>{subTitle}</CardDescription>}
              </CardLink>
            </ArticleCard>
          );
        })}
      </CardList>
      <Footer />
    </>
  );
};
