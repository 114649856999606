import { Content } from '../../content/Content';
import { ContentParagraph } from '../../content/ContentParagraph';
import { AboutWrapper } from '../../wrapper/AboutWrapper';

export const Me = (): JSX.Element => {
  return (
    <AboutWrapper title="About Me">
      <Content>
        <ContentParagraph>
          <strong>Hey! 👋🏻</strong>
        </ContentParagraph>
        <ContentParagraph>
          I'm Michael, a Software Engineer based in Margam, Wales where I live with my partner Rhian and our cat Kiwi.
        </ContentParagraph>
        <ContentParagraph style={{ textAlign: 'center' }}>👨🏻‍💻👩🏻‍🦰🐈</ContentParagraph>
        <ContentParagraph>
          In my downtime I love walking in the great outdoors, exploring new places, eating vegan food, working on side
          projects, and learning new things.
        </ContentParagraph>
        <ContentParagraph>
          I post occasionally on{' '}
          <a href="https://twitter.com/mjmasn" target="_blank">
            Twitter
          </a>{' '}
          and on Instagram{' '}
          <a href="https://www.instagram.com/mjmasn/" target="_blank">
            as myself
          </a>{' '}
          and{' '}
          <a href="https://www.instagram.com/acatcalledkiwi/" target="_blank">
            as the social media manager for our cat
          </a>
          . I'm also active on{' '}
          <a href="https://news.ycombinator.com/user?id=mjmasn" target="_blank">
            Hacker News
          </a>
          .
        </ContentParagraph>
      </Content>
    </AboutWrapper>
  );
};
