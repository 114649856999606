import styled from 'styled-components';

import { BREAKPOINT_TABLET } from '../../constants/styles';

export const ContentParagraph = styled.p`
  font-size: 18px;
  max-width: 36rem;
  margin: 0 auto;

  * + & {
    margin-top: 32px;
  }

  &:first-child {
    margin-top: 32px;

    @media screen and (max-width: ${BREAKPOINT_TABLET}) {
      margin-top: 16px;
    }
  }
`;
