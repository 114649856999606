import { Content } from '../../content/Content';
import { ContentParagraph } from '../../content/ContentParagraph';
import { AboutWrapper } from '../../wrapper/AboutWrapper';

export const Employment = (): JSX.Element => {
  return (
    <AboutWrapper title="Employment">
      <Content>
        <ContentParagraph>
          I currently work as a Senior Software Developer on the Mobile team at{' '}
          <a href="https://www.aforza.com" target="_blank">
            Aforza
          </a>
          . We develop a fully offline capable Retail Execution application using React Native and powered by the
          Salesforce platform.
        </ContentParagraph>
        <ContentParagraph>
          I previously worked at{' '}
          <a href="https://www.opengenius.com/" target="_blank">
            OpenGenius
          </a>{' '}
          on their{' '}
          <a href="https://www.ayoa.com/" target="_blank">
            Ayoa
          </a>{' '}
          product, initially working on the web and backend codebase and later leading development of the React Native
          app for Android.
        </ContentParagraph>
        <ContentParagraph>
          At{' '}
          <a href="https://www.elaura.com/" target="_blank">
            Elaura
          </a>{' '}
          (our family business), I developed the{' '}
          <a href="https://hoozyu.com/" target="_blank">
            hoozyu
          </a>{' '}
          and{' '}
          <a href="https://expresso.elaura.com/" target="_blank">
            expresso
          </a>{' '}
          platforms and the backend systems to manage them.
        </ContentParagraph>
      </Content>
    </AboutWrapper>
  );
};
