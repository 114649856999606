import styled from 'styled-components';

import type { ContentProps } from '../../../../types/content';
import { Content } from '../../../content/Content';
import { ContentParagraph } from '../../../content/ContentParagraph';
import { Timestamps } from '../../../timestamp/Timestamps';
import { ArticleWrapper } from '../../../wrapper/ArticleWrapper';

type WordleDetails = {
  description?: string;
  image?: string;
  title: string;
  url: string;
};

const wordles: WordleDetails[] = [
  {
    description: 'Guess the hidden word in 6 tries. A new puzzle is available each day.',
    image: 'https://www.nytimes.com/games/wordle/images/NYT-Wordle-Meta.png',
    title: 'Wordle',
    url: 'https://www.nytimes.com/games/wordle/index.html',
  },
  {
    description: 'Test your vocabulary by finding the words in six guesses or less!',
    image: 'https://www.lingolingolingo.com/social-compressed.png',
    title: 'LingoLingoLingo - Free word guessing game',
    url: 'https://www.lingolingolingo.com/',
  },
  {
    description: 'wordle plus wordle',
    image: 'https://img.itch.zone/aW1nLzc5NjAxODEucG5n/original/lr2evp.png',
    title: 'dordle by zaratustra',
    url: 'https://zaratustra.itch.io/dordle',
  },
  {
    description:
      'Put your skills to the test and solve four Wordles at once! You have 9 guesses to solve all four words. A new Quordle available each day to solve.',
    image: 'https://www.quordle.com/quordle-banner-1200.png',
    title: 'Quordle',
    url: 'https://www.quordle.com/',
  },
  {
    description: 'An adversarial version of Wordle',
    image: 'https://qntm.org/files/absurdle/favicon.png',
    title: 'Absurdle',
    url: 'https://qntm.org/files/absurdle/absurdle.html',
  },
  {
    description: 'Just like Wordle, but with swear words',
    image: 'https://sweardle.com/sweardle_fb2.png',
    title: 'Sweardle, the 4 letter word guessing game',
    url: 'https://sweardle.com/',
  },
  {
    description: 'Guess the hidden word in 6 tries. A new puzzle is available every day.',
    image: 'https://lewdlegame.com/Lewdle_Banner_Dark.png',
    title: 'Lewdle - A lewd daily word game',
    url: 'https://www.lewdlegame.com/',
  },
  {
    description: 'Wordle with a Twist',
    image: 'https://www.starwordle.com/StarWordleIcon_large.jpg',
    title: 'Star Wordle',
    url: 'https://www.starwordle.com/',
  },
  {
    description: 'Play Wordle with Unlimited Words. Unlimited plays each and every day.',
    image: 'https://www.wordleunlimited.com/unlimited.png',
    title: 'Wordle Unlimited',
    url: 'https://www.wordleunlimited.com/',
  },
  {
    description: 'Searchdle is like Wordle but with SEO terms.',
    image: 'https://wtfseo.com/searchdle/searchdle.png',
    title: 'Searchdle: an SEO version of Wordle',
    url: 'https://wtfseo.com/searchdle/',
  },
  {
    description:
      'Play Wordle more than once a day. WordGuessr is an unlimited Wordle clone that lets you play with words of different lengths from 3 to 7 letter words.',
    image: 'https://engaging-data.com/wp-content/uploads/2022/01/wordguessr.png',
    title: 'WordGuessr - a social Wordle unlimited clone',
    url: 'https://engaging-data.com/wordguessr-wordle/',
  },
  {
    description:
      'Put your skills to the test and solve eight Wordles at once! You have 13 guesses to solve all eight words. A new Octordle available each day to solve.',
    image: 'https://octordle.com/screenshot.png',
    title: 'Octordle',
    url: 'https://octordle.com/?mode=daily',
  },
  {
    description:
      "It's like wordle, but you solve 16 wordles at once! You get 21 guesses to solve all the puzzles. A unique sedecordle every day and unlimited free puzzles!",
    image: 'https://sedecordle.com/screenshot.png',
    title: 'sedecordle',
    url: 'http://www.sedecordle.com/',
  },
  {
    description: 'The daily numbers game',
    image: 'https://nerdlegame.com/logo512.png',
    title: 'Nerdle',
    url: 'https://nerdlegame.com/',
  },
  {
    description: 'Wordle in less than 50 lines of Bash',
    image: 'https://github.githubassets.com/images/modules/gists/gist-og-image.png',
    title: 'Bash Wordle',
    url: 'https://gist.github.com/huytd/6a1a6a7b34a0d0abcac00b47e3d01513',
  },
  {
    description:
      'Your favorite word game, now multiplayer! Play competitive multiplayer wordle against your friends or up to 99 people.',
    image: 'https://squabble.me/card.png',
    title: 'Squabble',
    url: 'https://squabble.me/',
  },
  {
    description:
      "A guessing game like Wordle, but you're trying to guess Dungeons & Dragons (5e) monsters by choosing characteristic scores.",
    image: 'https://dndle.app/og.png',
    title: 'DNDle',
    url: 'https://dndle.app/',
  },
  {
    description:
      "There's no word set by default. Every time you guess, I look at all possible 5-letter words that would fit all your guesses, and choose the match pattern that results in the most possible words. My goal is to maximize the amount of guesses it takes to find the word.",
    title: 'Evil Wordle',
    url: 'https://swag.github.io/evil-wordle/',
  },
  {
    description: 'A slightly harder version of Wordle',
    title: 'hurdle',
    url: 'https://playhurdle.vercel.app/',
  },
  {
    description: 'נחשו את המילה בתוך שישה ניחושים. כל יום תופיע מילה חדשה',
    title: 'וורדעל - משחק מילים יומי',
    url: 'https://wordleheb.web.app/',
  },
  {
    description:
      'Guess the JEWDLE in 6 tries. Words can range from English to Hebrew to Yiddish to Aramaic and everything in between.',
    title: 'Jewdle – a daily Jewish word game',
    url: 'https://www.jewdle.app/',
  },
  {
    description: 'You know Wordle right? Create a custom word and challenge your friends',
    title: 'Wordle with Friends - pick a word and challenge your friends!',
    url: 'https://mottaquikarim.github.io/wordle_with_friends/',
  },
  {
    description: 'Wordle game in the World',
    title: 'Worldle',
    url: 'https://worldle.teuteuf.fr/',
  },
  {
    description: `Why not? It's all the rage.`,
    title: 'Wordle over DNS',
    url: 'https://dgl.cx/2022/02/wordle-over-dns',
  },
  {
    description: 'Guess the SHA256 hash...',
    title: 'Passwordle',
    url: 'https://rsk0315.github.io/playground/passwordle.html',
  },
  {
    description: 'A daily math puzzle',
    title: 'Mathler',
    url: 'https://www.mathler.com/',
  },
  {
    description: 'A Taylor Swift Wordle Clone',
    title: 'Taylordle',
    url: 'https://www.taylordle.com/',
  },
  {
    description: 'A word-guessing game',
    title: 'Luckle',
    url: 'https://leahneukirchen.github.io/luckle/',
  },
  {
    description: 'A Wordle-inspired rule deduction game by Henry Truong.',
    title: 'OBSCURDLE',
    url: 'http://obscurdle.com/',
  },
  {
    description: 'A word-guessing game',
    title: 'hello wordl',
    url: 'https://hellowordl.net',
  },
  {
    title: 'Primel',
    url: 'https://converged.yt/primel/',
  },
  {
    title: 'Queerdle',
    url: 'https://queerdle.com/',
  },
  {
    title: 'BTS Wordle',
    url: 'https://bts-wordle.vercel.app/',
  },
  {
    title: 'Squirdle',
    url: 'https://squirdle.fireblend.com/daily',
  },
  {
    title: 'cl-wordle',
    url: 'https://crates.io/crates/cl-wordle',
  },
  {
    title: 'Hyperwordle',
    url: 'https://arhamjain.com/hyperwordle/',
  },
  {
    description: 'Create your own Wordle images',
    image: 'https://wordle.gary.mcad.am/og.png',
    title: 'Wordle Image Maker',
    url: 'https://wordle.gary.mcad.am/',
  },
  {
    description: 'Adivine la palabra en 6 intentos. Un nuevo desafio es generado cada día',
    image: 'https://santiagodebus.s3.sa-east-1.amazonaws.com/images/wordle_og_1200x630.png',
    title: 'Wordle Español',
    url: 'https://wordle.santiagodebus.com/',
  },
  {
    description: '',
    image: '',
    title: 'Bytle',
    url: 'https://jamesl.me/bytle/',
  },
  {
    description: '',
    image: '',
    title: 'Command Line Wordle (English + Spanish)',
    url: 'https://github.com/CarolinaCampi/Wordle',
  },
  {
    description: '',
    image: '',
    title: 'Crosswordle',
    url: 'https://crosswordle.vercel.app',
  },
  {
    description: '',
    image: '',
    title: 'Wordle NYC',
    url: 'https://wordle.nyc/',
  },
  {
    description: '',
    image: '',
    title: 'Mathle',
    url: 'https://mathlegame.com/',
  },
  {
    description: '',
    image: '',
    title: 'Reactle',
    url: 'https://reactle.vercel.app/',
  },
  {
    description: '',
    image: '',
    title: 'Termo (Portuguese Wordle)',
    url: 'https://term.ooo/',
  },
  {
    description: '',
    image: '',
    title: 'Gameboy Wordle',
    url: 'https://nezza.github.io/gbwordle/',
  },
  {
    description: '',
    image: '',
    title: 'Wordle Cup',
    url: 'https://wordlecup.io/',
  },
  {
    description: '',
    image: '',
    title: 'Worduel',
    url: 'https://worduel.me/',
  },
  {
    description: '',
    image: '',
    title: 'Hardle',
    url: 'https://hardle.herokuapp.com/',
  },
  {
    description: '',
    image: '',
    title: 'rordle',
    url: 'https://github.com/H4kor/rordle',
  },
  {
    description: '',
    image: '',
    title: 'clidle (SSH)',
    url: 'https://github.com/ajeetdsouza/clidle',
  },
  {
    description: '',
    image: '',
    title: 'Numble',
    url: 'https://www.thenumble.app/',
  },
  {
    description: '',
    image: '',
    title: 'Semantle',
    url: 'https://semantle.novalis.org/',
  },
  {
    description: '',
    image: '',
    title: 'Ruby Wordle',
    url: 'https://github.com/JonathanWThom/wordle',
  },
  {
    description: '',
    image: '',
    title: 'linkdd wordle',
    url: 'https://linkdd-wordle.netlify.app/',
  },
  {
    description: '',
    image: '',
    title: 'Hexle',
    url: 'https://jamesl.me/hexle/',
  },
  {
    description: '',
    image: '',
    title: 'Palm VII Wordle',
    url: 'https://twitter.com/Huxley_D/status/1489719206039805954',
  },
  {
    description: '',
    image: '',
    title: 'Squareword',
    url: 'https://squareword.org/',
  },
  {
    description: '',
    image: '',
    title: 'Heardle',
    url: 'https://heardle.glitch.me/',
  },
  {
    description: '',
    image: '',
    title: 'Green Blocks',
    url: 'https://greenblocks.jordan.matelsky.com/',
  },
  {
    description: '',
    image: '',
    title: 'Airportle',
    url: 'https://airportle.scottscheapflights.com/',
  },

  {
    description: '',
    image: '',
    title: 'Excel Wordle',
    url: 'https://old.reddit.com/r/excel/comments/sd8572/i_recreated_wordle_on_excel/',
  },
  {
    description: '',
    image: '',
    title: 'lexoula (Greek)',
    url: 'https://lexoula.com/',
  },
  {
    description: '',
    image: '',
    title: 'wordshl',
    url: 'https://github.com/cmdlineluser/wordshl',
  },
  {
    description: '',
    image: '',
    title: 'Wordle on Gameboy',
    url: 'https://twitter.com/ghidraninja/status/1490323167985995777',
  },
  {
    description: '',
    image: '',
    title: 'Mastermind Word',
    url: 'https://rrmm.github.io/mastermind-word/',
  },
  {
    description: '',
    image: '',
    title: 'wordlet',
    url: 'https://crates.io/crates/wordlet',
  },
  {
    description: '',
    image: '',
    title: 'Ukranian Wordle',
    url: 'https://slovko.zaxid.net/',
  },
  {
    description: '',
    image: '',
    title: 'Chinese Wordle',
    url: 'https://chinese-wordle.netlify.app/',
  },
  {
    description: '',
    image: '',
    title: 'Wortle',
    url: 'https://wortle.kosmi.io/',
  },
  {
    description: '',
    image: '',
    title: 'Plantle',
    url: 'https://plantle.netlify.app/',
  },
  {
    description: '',
    image: '',
    title: "Wordle for Word '97",
    url: 'https://github.com/nilsding/wordle97',
  },
  {
    description: '',
    image: '',
    title: 'Hello Wordle',
    url: 'https://hello-wordle.netlify.app/',
  },
  {
    description: '',
    image: '',
    title: 'Wordle PDF',
    url: 'https://wordlepdf.com/',
  },
  {
    description: '',
    image: '',
    title: 'Kilordle',
    url: 'https://jonesnxt.github.io/kilordle/',
  },
  {
    description: '',
    image: '',
    title: 'Google Easter Egg',
    url: 'https://www.google.com/search?q=wordle',
  },
  {
    description: '',
    image: '',
    title: 'Pordle',
    url: 'https://aptx314.pyweb.io/pordle/app/',
  },
  {
    description: '',
    image: '',
    title: 'Worddler',
    url: 'https://worddler.io/login',
  },
  {
    description: '',
    image: '',
    title: 'Turdle',
    url: 'https://turdle.xyz/',
  },
  {
    description: '',
    image: '',
    title: 'TypeScript Wordle',
    url: 'https://twitter.com/orta/status/1490631626828746753',
  },
];

const WordleLink = styled.a`
  display: block;
  text-decoration: none;
  color: #212121;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 16px;

  & + & {
    margin-top: 32px;
  }

  @media (prefers-color-scheme: dark) {
    color: #ffffff;
    border: 1px solid #333333;
  }
`;

const WordleImage = styled.img`
  border-radius: 4px;
  display: block;
  margin: 0 auto 8px auto;
`;

const WordleTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
`;

const WordleDescription = styled.p`
  font-size: 16px;
`;

export const AllTheWordles = (props: ContentProps): JSX.Element => {
  const { createdAt, title, updatedAt } = props;

  return (
    <ArticleWrapper title={title}>
      <Content>
        <Timestamps createdAt={createdAt} updatedAt={updatedAt} />
        <ContentParagraph>
          This is a list of all the Wordle clones and related tools that I've managed to find on the internet. Most of
          the list was compiled using{' '}
          <a href="https://hn.algolia.com" target="_blank">
            Hacker News search
          </a>
          , with the rest from memory.
        </ContentParagraph>
        <ContentParagraph>
          As Sod's law goes, after compiling a list of around 80 clones I stumbled upon an even more{' '}
          <a href="https://aloneonahill.com/blog/wordle-alternatives/" target="_blank">
            comprehensive list of Wordle clones
          </a>{' '}
          that includes a lot more international flavours of Wordle - so check that out too! I really lost the will to
          continue searching at that point but I may come back to it later.
        </ContentParagraph>
        <ContentParagraph>
          My favourite clone on the list would have to be{' '}
          <a href="https://www.lingolingolingo.com" target="_blank">
            Lingo Lingo Lingo
          </a>
          , but full disclosure I did create that one! 👀 It has a choice of 4-8 character words and you can play
          unlimited games for free.
        </ContentParagraph>
        <ContentParagraph>
          {wordles.map((wordle) => {
            const { description, title: wordleTitle, url, image } = wordle;

            return (
              <WordleLink href={url} target="_blank">
                {image === undefined ? null : <WordleImage src={image} />}
                <WordleTitle>{wordleTitle}</WordleTitle>
                <WordleDescription>{description}</WordleDescription>
              </WordleLink>
            );
          })}
        </ContentParagraph>
      </Content>
    </ArticleWrapper>
  );
};
