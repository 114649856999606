import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { BREAKPOINT_TABLET } from '../../constants/styles';

const TitleLink = styled(Link)`
  text-decoration: none;
  padding: 0 64px 0 0;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    padding: 0 32px 0 0;
  }
`;

const TitleText = styled.h1`
  padding: 0;
  margin: 0;
  display: inline;
  color: #ffffff;
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
`;

const SubtitleText = styled.h2`
  color: #ffffff99;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  padding: 4px 0 48px 0;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.3);

  @media screen and (max-width: 768px) {
    padding: 4px 0 24px 0;
  }
`;

const TitleWrapper = styled.div``;

export const Title = (): JSX.Element => {
  return (
    <TitleWrapper>
      <TitleLink to="/">
        <TitleText>Michael Mason</TitleText>
      </TitleLink>
      <SubtitleText>Software Engineer</SubtitleText>
    </TitleWrapper>
  );
};
