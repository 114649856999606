import styled from 'styled-components';

import { BREAKPOINT_TABLET } from '../../constants/styles';

export const BasicTitle = styled.h2`
  font-size: 48px;
  font-weight: 700;
  color: #ffffff;
  margin: 0;
  word-wrap: break-word;
  padding: 0 64px 64px 64px;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.3);

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    padding: 0 32px 32px 32px;
    font-size: 32px;
  }
`;
