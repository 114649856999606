import type { ContentProps } from '../../../../types/content';
import { Code } from '../../../code/Code';
import { Content } from '../../../content/Content';
import { ContentParagraph } from '../../../content/ContentParagraph';
import { Timestamps } from '../../../timestamp/Timestamps';
import { NoteWrapper } from '../../../wrapper/NoteWrapper';

export const ReactNativeCenteringListEmptyText = (props: ContentProps): JSX.Element => {
  const { createdAt, title, updatedAt } = props;

  return (
    <NoteWrapper title={title}>
      <Content>
        <Timestamps createdAt={createdAt} updatedAt={updatedAt} />
        <ContentParagraph>
          Often when rendering <Code inline language="tsx">{`<FlatList />`}</Code> or{' '}
          <Code inline language="tsx">{`<SectionList />`}</Code>, you want to render your{' '}
          <Code inline language="tsx">
            ListEmptyComponent
          </Code>{' '}
          with horizontal and vertically centered text. Just setting{' '}
          <Code inline language="tsx">
            flex: 1
          </Code>{' '}
          on the{' '}
          <Code inline language="tsx">
            contentContainerStyle
          </Code>{' '}
          isn't enough because this will prevent the view from scrolling when there is content.
        </ContentParagraph>
        <ContentParagraph>
          The solution is to use{' '}
          <Code inline language="tsx">
            flexGrow: 1
          </Code>{' '}
          instead.
        </ContentParagraph>
        <Code lineNumbers language="tsx" wide>
          {`
                <FlatList
                  contentContainerStyle={{ flexGrow: 1 }}
                  renderItem={() => {}}
                  ListEmptyComponent={() => (
                    <View
                      style={{
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Text style={{ textAlign: 'center' }}>Empty List!</Text>
                    </View>
                  )}
                />
              `}
        </Code>
      </Content>
    </NoteWrapper>
  );
};
