import styled from 'styled-components';

import { headerColors } from '../../constants/colors';
import { abouts } from '../../content/abouts';
import { Card } from '../card/Card';
import { CardDescription } from '../card/CardDescription';
import { CardLink } from '../card/CardLink';
import { CardList } from '../card/CardList';
import { CardTitle } from '../card/CartTitle';
import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';

const AboutCard = styled(Card)`
  &:nth-child(9n + 5) {
    background-color: hsl(148, 79%, 40%);
  }

  &:nth-child(9n + 9) {
    background-color: hsl(148, 79%, 37%);
  }

  &:nth-child(9n + 3) {
    background-color: hsl(148, 79%, 34%);
  }

  &:nth-child(9n + 8) {
    background-color: hsl(148, 79%, 31%);
  }

  &:nth-child(9n + 2) {
    background-color: hsl(148, 79%, 28%);
  }

  &:nth-child(9n + 6) {
    background-color: hsl(148, 79%, 25%);
  }

  &:nth-child(9n + 7) {
    background-color: hsl(148, 79%, 22%);
  }

  &:nth-child(9n + 1) {
    background-color: hsl(148, 79%, 19%);
  }

  &:nth-child(9n + 4) {
    background-color: hsl(148, 79%, 16%);
  }
`;

export const About = (): JSX.Element => {
  return (
    <>
      <Header color={headerColors.main} />
      <CardList>
        {abouts.map((about) => {
          const { slug, subTitle, title } = about;

          return (
            <AboutCard key={slug}>
              <CardLink to={slug}>
                <CardTitle>{title}</CardTitle>
                {subTitle === undefined ? null : <CardDescription>{subTitle}</CardDescription>}
              </CardLink>
            </AboutCard>
          );
        })}
      </CardList>
      <Footer />
    </>
  );
};
