import styled from 'styled-components';

export const Footnote = styled.p`
  max-width: 36rem;
  font-size: 12px;
  margin: 0 auto;

  * + & {
    margin-top: 32px;
  }
`;
