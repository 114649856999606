import type { SyntheticEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const FooterText = styled.p`
  font-size: 12px;
  color: #999999;
  padding: 0 64px 16px 64px;
  text-align: center;
`;

export const Footer = (): JSX.Element => {
  const currentYear = new Date().getFullYear();

  const { matches: initialPrefersDark } = window.matchMedia(`(prefers-color-scheme: dark)`);

  let initialOverrideValue = null;

  try {
    initialOverrideValue = localStorage.getItem('force-color-scheme');
  } catch {
    // Do nothing
  }

  const [prefersDark, setPrefersDark] = useState(initialPrefersDark);
  const [overrideValue, setOverrideValue] = useState(initialOverrideValue);

  useEffect(() => {
    document.documentElement.classList.remove('light', 'dark');

    if (overrideValue !== null) {
      document.documentElement.classList.add(overrideValue);
    }
  }, [overrideValue]);

  const onChangeColorScheme = useCallback((mediaQueryListEvent: MediaQueryListEvent) => {
    const { matches } = mediaQueryListEvent;
    setPrefersDark(matches);
  }, []);

  const onChangeStorage = useCallback(() => {
    try {
      const newOverrideValue = localStorage.getItem('force-color-scheme');
      setOverrideValue(newOverrideValue);
    } catch {
      // Do nothing
    }
  }, []);

  const onClickForceDark = useCallback(
    (clickEvent: SyntheticEvent) => {
      clickEvent.preventDefault();

      if (overrideValue === 'dark') {
        try {
          localStorage.removeItem('force-color-scheme');
        } catch {
          // Do nothing
        }

        setOverrideValue(null);
      } else {
        try {
          localStorage.setItem('force-color-scheme', 'dark');
        } catch {
          // Do nothing
        }

        setOverrideValue('dark');
      }
    },
    [overrideValue],
  );

  const onClickForceLight = useCallback(
    (clickEvent: SyntheticEvent) => {
      clickEvent.preventDefault();

      if (overrideValue === 'light') {
        try {
          localStorage.removeItem('force-color-scheme');
        } catch {
          // Do nothing
        }

        setOverrideValue(null);
      } else {
        try {
          localStorage.setItem('force-color-scheme', 'light');
        } catch {
          // Do nothing
        }

        setOverrideValue('light');
      }
    },
    [overrideValue],
  );

  useEffect(() => {
    const matcher = window.matchMedia(`(prefers-color-scheme: dark)`);
    matcher.addEventListener('change', onChangeColorScheme);
    window.addEventListener('storage', onChangeStorage);

    return () => {
      matcher.removeEventListener('change', onChangeColorScheme);
      window.removeEventListener('storage', onChangeStorage);
    };
  }, []);

  const isDark = (prefersDark && overrideValue !== 'light') || overrideValue === 'dark';

  return (
    <>
      <FooterText>&copy; 1991&ndash;{currentYear} Michael Mason</FooterText>
      <FooterText>
        {isDark ? (
          <a href="#" onClick={onClickForceLight}>
            <img style={{ display: 'inline-block' }} src="/light_mode_white_24dp.svg" />
          </a>
        ) : (
          <a href="#" onClick={onClickForceDark}>
            <img style={{ display: 'inline-block' }} src="/dark_mode_black_24dp.svg" />
          </a>
        )}
      </FooterText>
    </>
  );
};
