import { orderBy } from 'lodash';

import { Employment } from '../components/pages/about/Employment';
import { Me } from '../components/pages/about/Me';
import { OpenSource } from '../components/pages/about/OpenSource';
import { SkillsAndInterests } from '../components/pages/about/SkillsAndInterests';
import type { ContentItem } from '../types/content';

const content: ContentItem[] = [
  {
    component: Me,
    createdAt: new Date('2022-02-25T23:05:00.000Z'),
    hideFromHomepage: true,
    slug: '/about/me',
    title: 'About Me',
  },
  {
    component: Employment,
    createdAt: new Date('2022-02-25T23:09:00.000Z'),
    hideFromHomepage: true,
    slug: '/about/cv',
    title: 'Employment',
  },
  {
    component: SkillsAndInterests,
    createdAt: new Date('2022-02-25T23:09:00.000Z'),
    hideFromHomepage: true,
    slug: '/about/skills-and-interests',
    title: 'Skills & Interests',
  },
  {
    component: OpenSource,
    createdAt: new Date('2022-02-25T23:09:00.000Z'),
    hideFromHomepage: true,
    slug: '/about/open-source',
    title: 'Open Source',
  },
];

export const abouts = orderBy(content, ['createdAt'], ['desc']);
