import { orderBy } from 'lodash';

import { CloudflareRedirect } from '../components/pages/notes/2022-02-20/CloudflareRedirect';
import { ReactNativeCenteringListEmptyText } from '../components/pages/notes/2022-02-20/ReactNativeCenteringListEmptyText';
import { ReactNativeMetroCaching } from '../components/pages/notes/2022-02-20/ReactNativeMetroCaching';
import { PrettierAutomaticSemicolonInsertion } from '../components/pages/notes/2022-02-23/PrettierAutomaticSemicolonInsertion';
import { ReactNativeChromeDebuggerTimeSyncAndroid } from '../components/pages/notes/2022-02-25/ReactNativeChromeDebuggerTimeSyncAndroid';
import { StopWaitingForMetroBundles } from '../components/pages/notes/2022-02-25/StopWaitingForMetroBundles';
import type { ContentItem } from '../types/content';

const content: ContentItem[] = [
  {
    component: ReactNativeChromeDebuggerTimeSyncAndroid,
    createdAt: new Date('2022-02-25T16:37:00.000Z'),
    slug: '/notes/react-native-chrome-debugger-time-sync-android',
    title: 'Fixing time sync for React Native Android with Chrome Debugger',
  },
  {
    component: StopWaitingForMetroBundles,
    createdAt: new Date('2022-02-25T19:18:00.000Z'),
    slug: '/notes/stop-waiting-for-metro-bundles',
    title: 'Stop waiting for metro bundles',
  },
  {
    component: PrettierAutomaticSemicolonInsertion,
    createdAt: new Date('2022-02-23T12:06:00.000Z'),
    slug: '/notes/prettier-automatic-semicolon-insertion',
    title: 'Prettier and Automatic Semicolon Insertion',
  },
  {
    component: CloudflareRedirect,
    createdAt: new Date('2022-02-20T23:38:00.000Z'),
    slug: '/notes/cloudflare-redirect',
    subTitle: 'With the magic of dummy IPs',
    title: 'Use Cloudflare to redirect a domain name',
  },
  {
    component: ReactNativeCenteringListEmptyText,
    createdAt: new Date('2022-02-20T23:55:00.000Z'),
    slug: '/notes/react-native-centering-empty-list-text',
    title: 'Centering empty list text in React Native',
  },
  {
    component: ReactNativeMetroCaching,
    createdAt: new Date('2022-02-20T23:32:00.000Z'),
    slug: '/notes/react-native-metro-caching',
    title: 'Fix caching issues with React Native',
  },
];

export const notes = orderBy(content, ['createdAt'], ['desc']);
