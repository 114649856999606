import type { ContentProps } from '../../../../types/content';
import { Code } from '../../../code/Code';
import { Content } from '../../../content/Content';
import { ContentParagraph } from '../../../content/ContentParagraph';
import { Timestamps } from '../../../timestamp/Timestamps';
import { ArticleWrapper } from '../../../wrapper/ArticleWrapper';

export const WritingAQueueTypeScript = (props: ContentProps): JSX.Element => {
  const { createdAt, title, updatedAt } = props;

  return (
    <ArticleWrapper title={title}>
      <Content>
        <Timestamps createdAt={createdAt} updatedAt={updatedAt} />
        <ContentParagraph>...</ContentParagraph>
        <Code lineNumbers language="typescript" wide>
          {`
            class Queue {
              private abortControllers = [];
              private limit = 0;
              private queue = [];
              private running = 0;
              private completed = 0;
              private stopped = false;

              constructor(limit = 3) {
                this.limit = limit;
                console.log("Queue started with limit=", this.limit);
              }

              private async execute(item) {
                const [resolve, reject, fn, ...args] = item;

                try {
                  const result = await fn(...args);

                  resolve(result);
                } catch (err) {
                  reject(err);
                }
              }

              private complete() {
                this.running -= 1;
                this.completed += 1;
                this.run();
              }

              private run() {
                if (!this.stopped && this.queue.length && this.running < this.limit) {
                  const num = this.limit - this.running;
                  const item = this.queue.shift();

                  this.running += 1;
                  console.log(\`\${this.running} Running, \${this.completed} Complete\`);
                  this.execute(item);
                } else {
                  console.log(\`\${this.running} Running, \${this.completed} Complete\`);
                }
              }

              addToQueue(fn, ...args) {
                if (this.stopped) {
                  throw new Error("Queue is stopped");
                }

                return new Promise((res, rej) => {
                  const resolve = (...resolveArgs) => {
                    if (!this.stopped) {
                      res(...resolveArgs);
                    }
                    this.complete();
                  };

                  const reject = (...rejectArgs) => {
                    if (!this.stopped) {
                      rej(...rejectArgs);
                    }
                    this.complete();
                  };

                  this.queue.push([resolve, reject, fn, ...args]);

                  this.run();
                });
              }

              registerAbortController(controller) {
                if (this.stopped) {
                  throw new Error("Queue is stopped");
                }

                this.abortControllers.push(controller);
              }

              stop() {
                this.stopped = true;
                this.queue = [];
                this.abortControllers.forEach((controller) => controller.abort());
              }
            }

            const go = async () => {
              const q = new Queue(10);

              const myFn = async (...args) => {
                return new Promise((resolve, reject) => {
                  const delay = Math.random() * 5000;
                  setTimeout(() => {
                    resolve(args);
                  }, delay);
                });
              };

              const promises = [];

              for (let i = 0; i < 100; i += 1) {
                promises.push(q.addToQueue(myFn, Math.random()));
              }

              const results = await Promise.all(promises);

              console.log("Results", results);
            };

            go();
          `}
        </Code>
      </Content>
    </ArticleWrapper>
  );
};
