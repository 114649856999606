import styled from 'styled-components';
import { BREAKPOINT_TABLET } from '../../constants/styles';

const BottomImage = styled.img`
  display: block;
  position: fixed;
  bottom: 0;
  left: 32px;
  user-select: none;
  height: 70px;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    height: 50px;
    left: 8px;
  }
`;

export const Kiwi = () => {
  return (
    <a href="https://www.acatcalledkiwi.com/" target="_blank">
      <BottomImage src="/kiwi.png" srcSet="/kiwi.png 1x, /kiwi@2x.png 2x, /kiwi@3x.png 3x" alt="Kiwi the cat" />
    </a>
  );
};
