import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { About } from './components/route/About';
import { Articles } from './components/route/Articles';
import { Experiments } from './components/route/Experiments';
import { Home } from './components/route/Home';
import { Notes } from './components/route/Notes';
import { Projects } from './components/route/Projects';
import { allContent } from './content';

export const AppRoutes = (): JSX.Element => {
  const routerLocation = useLocation();

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 0,
    });
  }, [routerLocation]);

  return (
    <Routes>
      <Route path="/about" element={<About />} />
      <Route path="/articles" element={<Articles />} />
      <Route path="/experiments" element={<Experiments />} />
      <Route path="/notes" element={<Notes />} />
      <Route path="/projects" element={<Projects />} />
      {allContent.map((content) => {
        const { component: Component, createdAt, slug, subTitle, title, updatedAt } = content;

        return (
          <Route
            key={slug}
            path={slug}
            element={
              <Component createdAt={createdAt} slug={slug} subTitle={subTitle} title={title} updatedAt={updatedAt} />
            }
          />
        );
      })}
      <Route path="/" element={<Home />} />
    </Routes>
  );
};
