import { Content } from '../../content/Content';
import { ContentParagraph } from '../../content/ContentParagraph';
import { AboutWrapper } from '../../wrapper/AboutWrapper';

export const OpenSource = (): JSX.Element => {
  return (
    <AboutWrapper title="Open Source">
      <Content>
        <ContentParagraph>These are some small open source projects that I maintain:</ContentParagraph>
        <ContentParagraph>
          <a href="https://github.com/devsht" target="_blank">
            @devsht
          </a>{' '}
          - Collection of reusable configs for TypeScript, Babel, ESLint, and Prettier
        </ContentParagraph>
        <ContentParagraph>
          <a href="https://github.com/rctsht/material" target="_blank">
            @rctsht/material
          </a>{' '}
          - Material UI component library for React Native
        </ContentParagraph>
        <ContentParagraph>
          Aside from these I try to contribute issues and pull requests to various projects where I can, but I'd
          certainly like to do more in this space!
        </ContentParagraph>
      </Content>
    </AboutWrapper>
  );
};
