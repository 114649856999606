import type { ContentProps } from '../../../../types/content';
import { Code } from '../../../code/Code';
import { Content } from '../../../content/Content';
import { ContentParagraph } from '../../../content/ContentParagraph';
import { Timestamps } from '../../../timestamp/Timestamps';
import { NoteWrapper } from '../../../wrapper/NoteWrapper';

export const StopWaitingForMetroBundles = (props: ContentProps): JSX.Element => {
  const { createdAt, title, updatedAt } = props;

  return (
    <NoteWrapper title={title}>
      <Content>
        <Timestamps createdAt={createdAt} updatedAt={updatedAt} />
        <ContentParagraph>
          If you're fed up of having to wait for metro to bundle after the native side of your app builds, add these
          bookmarks to your browser.
        </ContentParagraph>
        <Code language="none">http://localhost:8081/index.bundle?platform=android&amp;dev=true&amp;minify=false</Code>
        <Code language="none">http://localhost:8081/index.bundle?platform=ios&amp;dev=true&amp;minify=false</Code>
        <ContentParagraph>
          Now you can click these and metro will begin bundling even before your native builds have completed. It's best
          to close the tab immediately after you see the progress bar appear in metro as the file is very large and has
          occasionally crashed my browser.
        </ContentParagraph>
      </Content>
    </NoteWrapper>
  );
};
