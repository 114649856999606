import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const CardLink = styled(Link)`
  display: flex;
  text-decoration: none;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  height: 100%;
  padding: 16px;
`;
