import { format } from 'date-fns';

import { Timestamp } from './Timestamp';

type Props = {
  createdAt: Date;
  updatedAt?: Date[];
};

export const Timestamps = (props: Props): JSX.Element => {
  const { createdAt, updatedAt } = props;

  return (
    <>
      <Timestamp>{format(createdAt, 'PPPP')}</Timestamp>
      {updatedAt?.map((updatedAtDate) => (
        <Timestamp>Updated: {format(updatedAtDate, 'PPPP')}</Timestamp>
      ))}
    </>
  );
};
