import type { ContentProps } from '../../../../types/content';
import { Content } from '../../../content/Content';
import { ContentImage } from '../../../content/ContentImage';
import { ContentParagraph } from '../../../content/ContentParagraph';
import { Timestamps } from '../../../timestamp/Timestamps';
import { ExperimentWrapper } from '../../../wrapper/ExperimentWrapper';

export const RctshtQrCode = (props: ContentProps): JSX.Element => {
  const { createdAt, title, updatedAt } = props;

  return (
    <ExperimentWrapper title={title}>
      <Content>
        <Timestamps createdAt={createdAt} updatedAt={updatedAt} />
        <ContentParagraph>
          <ContentImage src="https://user-images.githubusercontent.com/2977095/178069737-5a25e586-3d3e-48ef-a764-1da57880ae82.gif" />
        </ContentParagraph>
        <ContentParagraph>
          @rctsht/qrcode (
          <a href="https://www.npmjs.com/package/@rctsht/qrcode" target="_blank">
            NPM
          </a>
          ,{' '}
          <a href="https://github.com/rctsht/qrcode" target="_blank">
            Github
          </a>
          ) is a native QR-code generator library for React Native. It uses zxing on Android and Core Image
          QRCodeGenerator on iOS.
        </ContentParagraph>
        <ContentParagraph>
          This purpose of this experiment was to learn more about creating a native view library for React Native. I've
          created native modules in the past but not views, and in particular I want to explore the new architecture of
          React Native (Turbo Modules and Fabric). The initial version of the library is designed for the old
          architecture but I plan to add support for the shiny new one too.
        </ContentParagraph>
      </Content>
    </ExperimentWrapper>
  );
};
