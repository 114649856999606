import { orderBy } from 'lodash';

import { AllTheWordles } from '../components/pages/articles/2022-02-20/AllTheWordles';
import { VPSComparison } from '../components/pages/articles/2022-02-26/VPSComparison';
import { WalksInSouthWales } from '../components/pages/articles/2022-02-26/WalksInSouthWales';
import { WritingAQueueTypeScript } from '../components/pages/articles/2022-02-26/WritingAQueueTypeScript';
import type { ContentItem } from '../types/content';

const content: ContentItem[] = [
  // {
  //   component: AllTheWordles,
  //   createdAt: new Date('2022-02-20T21:27:00.000Z'),
  //   slug: '/articles/all-the-wordles',
  //   subTitle: "A list of all the Wordle clones and related tools that I've managed to find on the internet",
  //   title: 'All The Wordles!',
  //   updatedAt: [new Date('2022-02-28T20:13:00.000Z'), new Date('2022-03-01T17:51:00.000Z')],
  // },
  // {
  //   component: VPSComparison,
  //   createdAt: new Date('2022-02-26T13:18:00.000Z'),
  //   slug: '/articles/vps-comparison',
  //   subTitle: 'Comparison of Digital Ocean, Hetzner Cloud, Linode, and UpCloud',
  //   title: 'Virtual Private Servers',
  // },
  // {
  //   component: WalksInSouthWales,
  //   createdAt: new Date('2022-02-26T13:22:00.000Z'),
  //   slug: '/articles/walks-in-south-wales',
  //   title: 'Walks in South Wales',
  // },
  // {
  //   component: WritingAQueueTypeScript,
  //   createdAt: new Date('2022-02-26T13:41:00.000Z'),
  //   slug: '/articles/writing-a-queue-with-typescript',
  //   title: 'Writing a Queue with TypeScript',
  // },
];

export const articles = orderBy(content, ['createdAt'], ['desc']);
