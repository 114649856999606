import styled from 'styled-components';

import { headerColors } from '../../constants/colors';
import { projects } from '../../content/projects';
import { Card } from '../card/Card';
import { CardDescription } from '../card/CardDescription';
import { CardLink } from '../card/CardLink';
import { CardList } from '../card/CardList';
import { CardTitle } from '../card/CartTitle';
import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';
import { BasicTitle } from '../title/BasicTitle';

const ProjectCard = styled(Card)`
  &:nth-child(9n + 7) {
    background-color: hsl(221, 79%, 40%);
  }

  &:nth-child(9n + 9) {
    background-color: hsl(221, 79%, 37%);
  }

  &:nth-child(9n + 2) {
    background-color: hsl(221, 79%, 34%);
  }

  &:nth-child(9n + 8) {
    background-color: hsl(221, 79%, 31%);
  }

  &:nth-child(9n + 5) {
    background-color: hsl(221, 79%, 28%);
  }

  &:nth-child(9n + 3) {
    background-color: hsl(221, 79%, 25%);
  }

  &:nth-child(9n + 4) {
    background-color: hsl(221, 79%, 22%);
  }

  &:nth-child(9n + 6) {
    background-color: hsl(221, 79%, 19%);
  }

  &:nth-child(9n + 1) {
    background-color: hsl(221, 79%, 16%);
  }
`;

export const Projects = (): JSX.Element => {
  return (
    <>
      <Header color={headerColors.project}>
        <BasicTitle>Projects</BasicTitle>
      </Header>
      <CardList>
        {projects.map((note) => {
          const { slug, subTitle, title } = note;

          return (
            <ProjectCard key={slug}>
              <CardLink to={slug}>
                <CardTitle>{title}</CardTitle>
                {subTitle === undefined ? null : <CardDescription>{subTitle}</CardDescription>}
              </CardLink>
            </ProjectCard>
          );
        })}
      </CardList>
      <Footer />
    </>
  );
};
