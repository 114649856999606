import type { ContentProps } from '../../../../types/content';
import { Code } from '../../../code/Code';
import { Content } from '../../../content/Content';
import { ContentParagraph } from '../../../content/ContentParagraph';
import { Timestamps } from '../../../timestamp/Timestamps';
import { NoteWrapper } from '../../../wrapper/NoteWrapper';

export const PrettierAutomaticSemicolonInsertion = (props: ContentProps): JSX.Element => {
  const { createdAt, title, updatedAt } = props;

  return (
    <NoteWrapper title={title}>
      <Content>
        <Timestamps createdAt={createdAt} updatedAt={updatedAt} />
        <ContentParagraph>Had an interesting question from a colleague today: What's going on here?</ContentParagraph>
        <Code lineNumbers language="tsx">
          {`
            let value = 'a'
            if (condition) {
                ;[value] = ['b']
            }
          `}
        </Code>
        <ContentParagraph>
          In our project we don't use semicolons so we're relying on ASI (Automatic Semicolon Insertion). Indeed this
          code does work perfectly fine without the semicolon. So why did Prettier feel the need to add an explicit
          semicolon here?
        </ContentParagraph>
        <ContentParagraph>
          This is actually explained nicely on the{' '}
          <a href="https://prettier.io/docs/en/rationale.html#semicolons" target="_blank">
            Prettier Rationale
          </a>{' '}
          webpage. Yes the code works without the semicolon, but what if later on someone adds a new line:
        </ContentParagraph>
        <Code lineNumbers language="tsx">
          {`
            let value = 'a'
            if (condition) {
                myFunction()
                [value] = ['b']
            }
          `}
        </Code>
        <ContentParagraph>Now we have a problem, the code will be interpreted as:</ContentParagraph>
        <Code lineNumbers language="tsx">
          {`
            let value = 'a'
            if (condition) {
                myFunction()[value] = ['b']
            }
          `}
        </Code>
        <ContentParagraph>
          So the answer is that the explicit semicolon prevents bugs being introduced further down the road. So we can
          thank the Prettier developers for thinking ahead!
        </ContentParagraph>
      </Content>
    </NoteWrapper>
  );
};
