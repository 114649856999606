import type { ContentProps } from '../../../../types/content';
import { Content } from '../../../content/Content';
import { ContentImage } from '../../../content/ContentImage';
import { ContentParagraph } from '../../../content/ContentParagraph';
import { Timestamps } from '../../../timestamp/Timestamps';
import { ProjectWrapper } from '../../../wrapper/ProjectWrapper';

export const LingoLingoLingo = (props: ContentProps): JSX.Element => {
  const { createdAt, title, updatedAt } = props;

  return (
    <ProjectWrapper title={title}>
      <Content>
        <Timestamps createdAt={createdAt} updatedAt={updatedAt} />
        <ContentParagraph>
          <ContentImage src="https://www.lingolingolingo.com/social-compressed.png" />
        </ContentParagraph>
        <ContentParagraph>
          <a href="https://www.lingolingolingo.com" target="_blank">
            Lingo Lingo Lingo
          </a>{' '}
          is a Wordle / Lingo clone that I created at the start of 2022.
        </ContentParagraph>
        <ContentParagraph>
          You can choose to solve words between 4 and 8 characters long, and can solve unlimited words for free!
        </ContentParagraph>
      </Content>
    </ProjectWrapper>
  );
};
