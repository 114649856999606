import styled from 'styled-components';

import { BREAKPOINT_TABLET } from '../../constants/styles';

export const CardList = styled.div`
  margin: 32px 32px 64px 32px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
  grid-gap: 32px;
  justify-content: space-between;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    margin: 16px 16px 32px 16px;
    grid-gap: 16px;
  }
`;
