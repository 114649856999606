import { orderBy } from 'lodash';

import { HigherLowerCardGame } from '../components/pages/experiments/2022-01-08/HigherLowerCardGame';
import { RctshtQrCode } from '../components/pages/experiments/2022-07-08/RctshtQrCode';
import { Chartacles } from '../components/pages/experiments/Chartacles';
import { ThankUNext } from '../components/pages/experiments/ThankUNext';
import type { ContentItem } from '../types/content';

const content: ContentItem[] = [
  {
    component: HigherLowerCardGame,
    createdAt: new Date('2022-01-08T17:38:00.000Z'),
    slug: '/experiments/higher-lower-card-game',
    subTitle: 'Bring your own beverage',
    title: 'Higher Lower Card Game',
  },
  {
    component: RctshtQrCode,
    createdAt: new Date('2022-07-08T21:00:00.000Z'),
    slug: '/experiments/qr-code',
    subTitle: 'Native QR code generation in React Native with zxing on Android and Core Image QRCodeGenerator on iOS',
    title: 'QR code generator for React Native',
  },

  // {
  //   component: ThankUNext,
  //   createdAt: new Date('2022-02-25T23:38:00.000Z'),
  //   slug: '/experiments/thank-u-next',
  //   subTitle: 'Add any song to my spotify queue!',
  //   title: 'Thank U, Next',
  // },
  // {
  //   component: Chartacles,
  //   createdAt: new Date('2022-02-25T23:39:00.000Z'),
  //   slug: '/experiments/chartacles',
  //   subTitle: 'Follow energy usage trends with the Octopus Energy API',
  //   title: 'Chartacles',
  // },
];

export const experiments = orderBy(content, ['createdAt'], ['desc']);
