import type { ReactNode } from 'react';

import { headerColors } from '../../constants/colors';
import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';
import { PageTitle } from '../title/PageTitle';

type Props = {
  children: ReactNode;
  title: string;
};

export const NoteWrapper = (props: Props): JSX.Element => {
  const { children, title } = props;

  return (
    <>
      <Header color={headerColors.note}>
        <PageTitle>{title}</PageTitle>
      </Header>
      {children}
      <Footer />
    </>
  );
};
