import type { ReactNode } from 'react';
import styled from 'styled-components';

import { headerColors } from '../../constants/colors';
import { BREAKPOINT_TABLET } from '../../constants/styles';
import { Mike } from '../heads/Mike';

import { Navigation } from './Navigation';
import { Title } from './Title';

type HeaderWrapperProps = {
  color?: string;
};

const HeaderWrapper = styled.header`
  background-color: ${(props: HeaderWrapperProps): string => props.color ?? headerColors.main};
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 48px 144px 0 64px;
  flex-wrap: wrap;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    padding: 24px 72px 0 32px;
  }
`;

type Props = {
  children?: ReactNode;
  color?: string;
};

export const Header = (props: Props): JSX.Element => {
  const { children, color } = props;

  return (
    <HeaderWrapper color={color}>
      <TopWrapper>
        <Title />
        <Navigation />
      </TopWrapper>
      {children}
      <Mike />
    </HeaderWrapper>
  );
};
