import styled from 'styled-components';

export const Timestamp = styled.div`
  margin: 16px 0 0 0;
  color: #999999;
  font-size: 12px;
  text-align: center;

  & + & {
    margin: 0;
  }
`;
