import { BrowserRouter } from 'react-router-dom';

import { AppRoutes } from './AppRoutes';
import { Kiwi } from './components/heads/Kiwi';

export const App = (): JSX.Element => {
  return (
    <>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
      <Kiwi />
    </>
  );
};
