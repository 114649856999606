import styled from 'styled-components';

const HeaderImage = styled.img`
  display: block;
  position: fixed;
  top: 0;
  right: 32px;
  height: 120px;
  transform: rotate(180deg);
  user-select: none;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    right: 16px;
    height: 60px;
  }
`;

export const Mike = () => {
  const onClick = (): void => {
    document.body.style.animation = 'spin 0.75s ease-in-out 1';

    // TODO: Use animation finished events instead?
    setTimeout(() => {
      document.body.style.animation = '';
    }, 1000);
  };

  return (
    <HeaderImage
      src="/mike-head.png"
      srcSet="/mike-head.png 1x, /mike-head@2x.png 2x, /mike-head@3x.png 3x"
      onClick={onClick}
    />
  );
};
