import styled from 'styled-components';

import { BREAKPOINT_TABLET } from '../../constants/styles';

export const PageTitle = styled.h2`
  font-size: 48px;
  font-weight: 700;
  max-width: min(calc(100% - 128px), 36rem);
  color: #ffffff;
  margin: 0 auto;
  word-wrap: break-word;
  padding-bottom: 64px;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.3);

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    margin: 0 32px;
    max-width: min(calc(100% - 64px), 36rem);
    padding-bottom: 32px;
    font-size: 32px;
  }
`;
