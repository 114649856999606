import type { ContentProps } from '../../../../types/content';
import { Content } from '../../../content/Content';
import { ContentImage } from '../../../content/ContentImage';
import { ContentParagraph } from '../../../content/ContentParagraph';
import { Footnote } from '../../../footnote/Footnote';
import { Timestamps } from '../../../timestamp/Timestamps';
import { ExperimentWrapper } from '../../../wrapper/ExperimentWrapper';

export const HigherLowerCardGame = (props: ContentProps): JSX.Element => {
  const { createdAt, title, updatedAt } = props;

  return (
    <ExperimentWrapper title={title}>
      <Content>
        <Timestamps createdAt={createdAt} updatedAt={updatedAt} />
        <ContentParagraph>
          <ContentImage src="https://www.higherlowercardgame.com/social-compressed.png" />
        </ContentParagraph>
        <ContentParagraph>
          <a href="https://www.higherlowercardgame.com" target="_blank">
            Higher Lower Card Game
          </a>{' '}
          is a quick website I threw together to allow playing the 'higher or lower' game without needing a physical
          deck of cards.
        </ContentParagraph>
        <ContentParagraph>
          The game will run through a shuffled desk of 52 cards (so 51 guesses), and can then be re-shuffled. Your score
          is shown underneath the higher/lower buttons.
        </ContentParagraph>
        <ContentParagraph>
          Card designs are public domain, courtesy of{' '}
          <a href="https://www.me.uk/cards/" target="_blank">
            Adrian Kennard (RevK)
          </a>{' '}
          <sup>
            <a href="#fn1" id="ref1">
              1
            </a>
          </sup>
        </ContentParagraph>
        <Footnote>
          <sup>
            <a href="#ref1" id="fn1">
              1
            </a>
          </sup>{' '}
          RevK also happens to be the owner of Andrews &amp; Arnold (AAISP). Their{' '}
          <a href="https://www.aa.net.uk/broadband/l2tp-service/" target="_blank">
            L2TP Service
          </a>{' '}
          got me through the tough time of having to use CGNAT-ed 4G internet before full fibre arrived on our estate.
        </Footnote>
      </Content>
    </ExperimentWrapper>
  );
};
