import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { BREAKPOINT_TABLET } from '../../constants/styles';

const NavWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 48px;
  flex: 1 1 auto;
  gap: 32px;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    gap: 16px;
    padding-bottom: 24px;
  }
`;

const NavLink = styled(Link)`
  display: inline-block;
  color: #ffffff;
  font-size: 24px;
  line-height: 24px;
  text-decoration: none;
  font-weight: 400;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.3);

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    font-size: 18px;
    line-height: 18px;
  }
`;

export const Navigation = (): JSX.Element => {
  return (
    <NavWrapper>
      <NavLink to="/articles">Articles</NavLink>
      <NavLink to="/notes">Notes &amp; Snippets</NavLink>
      <NavLink to="/experiments">Experiments</NavLink>
      <NavLink to="/projects">Projects</NavLink>
      <NavLink to="/about">About</NavLink>
    </NavWrapper>
  );
};
