import type { ContentProps } from '../../../../types/content';
import { Code } from '../../../code/Code';
import { Content } from '../../../content/Content';
import { ContentParagraph } from '../../../content/ContentParagraph';
import { Timestamps } from '../../../timestamp/Timestamps';
import { NoteWrapper } from '../../../wrapper/NoteWrapper';

export const ReactNativeMetroCaching = (props: ContentProps): JSX.Element => {
  const { createdAt, title, updatedAt } = props;

  return (
    <NoteWrapper title={title}>
      <Content>
        <Timestamps createdAt={createdAt} updatedAt={updatedAt} />
        <ContentParagraph>
          Switching branches frequently can really confuse metro, the React Native bundler. After closing metro
          (preferably) and switching branches the trick is to clear the watchman cache using:
        </ContentParagraph>
        <Code language="shell">watchman watch-del-all</Code>
        <ContentParagraph>then run metro with:</ContentParagraph>
        <Code language="shell">npm start -- --reset-cache</Code>
        <ContentParagraph>
          (The first{' '}
          <Code inline language="shell">
            --
          </Code>{' '}
          ensures that any following arguments are passed to metro, not npm.)
          <br />
          <br />
          I've never had a caching issue since running these two commands after switching branches.
        </ContentParagraph>
      </Content>
    </NoteWrapper>
  );
};
