import styled from 'styled-components';

export const ContentHeader = styled.h3`
  font-size: 18px;
  max-width: 36rem;
  margin: 32px auto;

  a {
    color: #212121;
    font-weight: 600;
    text-decoration: none;
  }
`;
