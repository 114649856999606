import styled from 'styled-components';

import { BREAKPOINT_TABLET } from '../../constants/styles';

export const Content = styled.article`
  padding: 32px 64px 64px 64px;
  //   max-width: calc(36rem + 128px);
  //   margin: 0 auto;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    padding: 16px 32px 32px 32px;
    // max-width: calc(36rem + 64px);
  }
`;
