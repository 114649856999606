import type { ContentProps } from '../../../../types/content';
import { Code } from '../../../code/Code';
import { Content } from '../../../content/Content';
import { ContentParagraph } from '../../../content/ContentParagraph';
import { Timestamps } from '../../../timestamp/Timestamps';
import { NoteWrapper } from '../../../wrapper/NoteWrapper';

export const ReactNativeChromeDebuggerTimeSyncAndroid = (props: ContentProps): JSX.Element => {
  const { createdAt, title, updatedAt } = props;

  return (
    <NoteWrapper title={title}>
      <Content>
        <Timestamps createdAt={createdAt} updatedAt={updatedAt} />
        <ContentParagraph>
          If you're using a Mac for React Native development, and you're struggling to use the Chrome debugger with
          Android because tapping the screen appears to do nothing, you may need to sync up the current time on your
          development machine and android device time.
        </ContentParagraph>
        <ContentParagraph>
          One way to do this is to ensure both are using the same Network Time Protocol server.
        </ContentParagraph>
        <ContentParagraph>
          On your Mac: check your System Preferences &gt; Time &amp; Date for the time server you're using. Mine is{' '}
          <strong>time.apple.com</strong>. Now in your terminal (with your Android device connected via ADB) run:
        </ContentParagraph>
        <Code language="shell">adb shell settings put global ntp_server time.apple.com</Code>
        <ContentParagraph>
          There's some more info on this issue{' '}
          <a href="https://github.com/facebook/react-native/issues/29591" target="_blank">
            here
          </a>
          .
        </ContentParagraph>
      </Content>
    </NoteWrapper>
  );
};
