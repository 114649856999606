import { orderBy } from 'lodash';

import type { ContentItem } from '../types/content';

import { abouts } from './abouts';
import { articles } from './articles';
import { experiments } from './experiments';
import { notes } from './notes';
import { projects } from './projects';

const content: ContentItem[] = [...abouts, ...articles, ...experiments, ...notes, ...projects];

export const allContent = orderBy(content, ['createdAt'], ['desc']);
