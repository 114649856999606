import type { ContentProps } from '../../../../types/content';
import { Content } from '../../../content/Content';
import { ContentHeader } from '../../../content/ContentHeader';
import { ContentParagraph } from '../../../content/ContentParagraph';
import { Timestamps } from '../../../timestamp/Timestamps';
import { ArticleWrapper } from '../../../wrapper/ArticleWrapper';

export const VPSComparison = (props: ContentProps): JSX.Element => {
  const { createdAt, title, updatedAt } = props;

  return (
    <ArticleWrapper title={title}>
      <Content>
        <Timestamps createdAt={createdAt} updatedAt={updatedAt} />
        <ContentParagraph>
          <ContentHeader>Digital Ocean</ContentHeader>
        </ContentParagraph>
        <ContentParagraph>
          <ContentHeader>Hetzner</ContentHeader>
        </ContentParagraph>
        <ContentParagraph>
          <ContentHeader>Linode</ContentHeader>
        </ContentParagraph>
        <ContentParagraph>
          <ContentHeader>UpCloud</ContentHeader>
        </ContentParagraph>
      </Content>
    </ArticleWrapper>
  );
};
