import { Content } from '../../content/Content';
import { ContentHeader } from '../../content/ContentHeader';
import { ContentParagraph } from '../../content/ContentParagraph';
import { AboutWrapper } from '../../wrapper/AboutWrapper';

export const SkillsAndInterests = (): JSX.Element => {
  return (
    <AboutWrapper title="Skills &amp; Interests">
      <Content>
        <ContentParagraph>
          I'm mostly interested in web-based or web-adjacent tech. Some of the things I've dabbled in are listed below:
        </ContentParagraph>
        <ContentHeader>Front-end</ContentHeader>
        <ContentParagraph>
          <ul>
            <li>React</li>
            <li>React Native</li>
            <li>Android (Java, Kotlin)</li>
            <li>iOS (Objective-C, Swift)</li>
          </ul>
        </ContentParagraph>
        <ContentHeader>Backend Systems</ContentHeader>
        <ContentParagraph>
          <ul>
            <li>Node.js</li>
            <li>Salesforce</li>
          </ul>
        </ContentParagraph>
        <ContentHeader>Operating Systems</ContentHeader>
        <ContentParagraph>
          <ul>
            <li>MacOS</li>
            <li>Linux (mainly Ubuntu)</li>
          </ul>
        </ContentParagraph>
        <ContentHeader>AWS Services</ContentHeader>
        <ContentParagraph>
          <ul>
            <li>Simple Email Service (SES)</li>
            <li>Simple Queue Service (SQS)</li>
            <li>Simple Storage Service (S3)</li>
            <li>CloudFront</li>
          </ul>
        </ContentParagraph>
        <ContentHeader>Databases</ContentHeader>
        <ContentParagraph>
          <ul>
            <li>MongoDB</li>
            <li>PostgreSQL</li>
            <li>SQLite</li>
          </ul>
        </ContentParagraph>
        <ContentHeader>Containerization / Service Discovery</ContentHeader>
        <ContentParagraph>
          <ul>
            <li>Docker</li>
            <li>Kubernetes</li>
            <li>Hashicorp Nomad</li>
            <li>Hashicorp Consul</li>
          </ul>
        </ContentParagraph>
        <ContentHeader>Reverse Proxies / Load Balancers</ContentHeader>
        <ContentParagraph>
          <ul>
            <li>HAProxy</li>
            <li>nginx</li>
          </ul>
        </ContentParagraph>
        <ContentHeader>CDN Providers</ContentHeader>
        <ContentParagraph>
          <ul>
            <li>CloudFront</li>
            <li>Cloudflare</li>
            <li>Netlify</li>
          </ul>
        </ContentParagraph>
        <ContentHeader>VPS Providers</ContentHeader>
        <ContentParagraph>
          <ul>
            <li>DigitalOcean</li>
            <li>Hetzner Cloud</li>
            <li>Linode</li>
            <li>UpCloud</li>
          </ul>
        </ContentParagraph>
      </Content>
    </AboutWrapper>
  );
};
