import type { ContentProps } from '../../../types/content';
import { Code } from '../../code/Code';
import { Content } from '../../content/Content';
import { ContentParagraph } from '../../content/ContentParagraph';
import { Timestamps } from '../../timestamp/Timestamps';
import { ExperimentWrapper } from '../../wrapper/ExperimentWrapper';

export const ThankUNext = (props: ContentProps): JSX.Element => {
  const { createdAt, title, updatedAt } = props;

  return (
    <ExperimentWrapper title={title}>
      <Content>
        <Timestamps createdAt={createdAt} updatedAt={updatedAt} />
        <ContentParagraph>
          Need to redirect a domain that is using Cloudflare DNS? Don't want to set up a server or page to do so? You're
          in luck...
        </ContentParagraph>
        <ContentParagraph>
          The trick is to set @ (root) &mdash; and www if desired &mdash; <strong>A</strong> records pointing to{' '}
          <Code inline language="none">
            192.0.2.0
          </Code>{' '}
          and <strong>AAAA</strong> records pointing to{' '}
          <Code inline language="none">
            2001:db8::
          </Code>
          . You must turn proxying on (the orange cloud) for this to work!
          <img src="/page-assets/cloudflare-redirect/cloudflare-dns.png" />
        </ContentParagraph>
        <ContentParagraph>
          Now you can set page rules as follows (using your own domain name, obviously)
          <img src="/page-assets/cloudflare-redirect/cloudflare-rules.png" />
        </ContentParagraph>
        <ContentParagraph>
          This is the exact setup I'm using to forward{' '}
          <a href="https://www.acatcalledkiwi.com" target="_blank">
            acatcalledkiwi.com
          </a>{' '}
          to Kiwi's Instagram page. It works because we just need any non-Cloudflare IP address, it'll never actually be
          fetched because of the redirect rules. These specific IP addresses are ones reserved for documentation
          purposes, so you can be sure they aren't going to resolve in the wild.
        </ContentParagraph>
      </Content>
    </ExperimentWrapper>
  );
};
