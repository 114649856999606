import { useEffect } from 'react';
import styled from 'styled-components';

type PreWrapperProps = {
  wide?: boolean;
};

const PreWrapper = styled.pre`
  margin: 0 auto;

  max-width: ${(props: PreWrapperProps): string => {
    return props.wide === true ? '54rem' : '36rem';
  }};
`;

type Props = {
  children: string;
  inline?: boolean;
  language: string;
  lineNumbers?: boolean;
  wide?: boolean;
};

export const Code = (props: Props): JSX.Element => {
  const { children, inline = false, language, lineNumbers = false, wide = false } = props;

  useEffect(() => {
    // @ts-expect-error using vendored / customised Prism not from npm (can't use babel plugin as we're using esbuild)
    Prism.highlightAll();
  }, []);

  const code = <code className={`language-${language}`}>{children}</code>;

  return inline ? (
    code
  ) : (
    <PreWrapper wide={wide}>
      <pre className={lineNumbers ? 'line-numbers' : undefined}>{code}</pre>
    </PreWrapper>
  );
};
