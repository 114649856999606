import styled from 'styled-components';

export const Card = styled.div`
  border-radius: 4px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media screen and (min-width: 624px) {
    aspect-ratio: 16 / 10;
  }
`;
