import { orderBy } from 'lodash';

import { LingoLingoLingo } from '../components/pages/projects/2022-01-05/LingoLingoLingo';
import type { ContentItem } from '../types/content';

const content: ContentItem[] = [
  {
    component: LingoLingoLingo,
    createdAt: new Date('2022-01-05T21:22:00.000Z'),
    slug: '/projects/lingo-lingo-lingo',
    subTitle:
      'The illegitimate child of Lingo (TV Show) and Wordle. Practice unlimited words and choose between 4 and 8 characters to guess.',
    title: 'Lingo Lingo Lingo',
  },
];

export const projects = orderBy(content, ['createdAt'], ['desc']);
